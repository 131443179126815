export const environment = {
  production: true,
  version: '55b6406d46c8260e49598d41a6c23573bcb07d5e',
  googleMapsApiKey: 'AIzaSyAiv8XnKboepzWpLJW_dB6H4glm8WFDzTE',
  weatherApiKey: 'ecd5bb0efd934aea9a5122330232811',
  weatherApiUrl: 'https://api.weatherapi.com/v1',
  apiBaseUrl: '/api',
  apiBaseAdminUrl: '/api/admin',
  authUrl: 'https://brainapp.us.auth0.com',
  apiAirQualityUrl: 'https://airquality.googleapis.com/v1',
  authClientId: 'e0jIDxskLX0vlvRbwQhR50XUHozEnui0',
  authGrantType: 'password',
};
